import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import OrderDisplay from 'components/OrderDisplay/OrderDisplay';
import InvoiceHeader from './InvoiceHeader/InvoiceHeader';
import CompactInvoice from './CompactInvoice/CompactInvoice';

import { ContentContainer } from './Invoice.styles';

const Invoice = ({ order, orderStatuses, paymentMethods, countries, productTypesConst, isCompact }) => {
  const { t } = useTranslation(['pageOrderDetails']);

  return isCompact ? (
    <CompactInvoice t={t} order={order} countries={countries} orderStatuses={orderStatuses} productTypesConst={productTypesConst} />
  ) : (
    <ContentContainer>
      <InvoiceHeader t={t} order={order} orderStatuses={orderStatuses} countries={countries} />
      <OrderDisplay
        size="small"
        order={order}
        orderStatuses={orderStatuses}
        paymentMethods={paymentMethods}
        countries={countries}
        productTypesConst={productTypesConst}
        isOnPrintingPage
        isAllowDisplayCheckoutLink={false}
      />
      <p style={{ fontSize: '12px', marginTop: '4px' }}>This invoice is computer generated and no signature is required</p>
    </ContentContainer>
  );
};

Invoice.propTypes = {
  order: PropTypes.object.isRequired,
  orderStatuses: PropTypes.array,
  paymentMethods: PropTypes.array,
  countries: PropTypes.array,

  isCompact: PropTypes.bool
};

export default Invoice;

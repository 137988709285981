import { useEffect, useState } from 'react';
import { MessageOutlined, TagOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Popover, Row, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetProductSuggestions } from 'apis/product';
import { withAppContext } from 'contexts/AppContext/AppContext';
import FormInput from 'components/FormInput/FormInput';

import { checkIsArrayEmpty, constructDisplayPrice } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

import { FormContentContainer, FormActionButtonsContainer, TooltipIcon } from './ProductKeywordModal.styles';

const { useForm } = Form;

const constructQtyLeftDisplay = (productKeyword, variances = []) => {
  return (
    <Popover
      trigger="click"
      content={variances.map(variance => (
        <p key={variance.keyword}>
          <TagOutlined /> {variance.label} x {variance.inventory}
        </p>
      ))}
    >
      <TooltipIcon />
    </Popover>
  );
};

const constructPriceDisplayForProductWithVariances = (variances = [], currencyISO) => {
  const variancesPriceList = variances.map(variance => Number(variance.price));
  const maxPrice = Math.max(...variancesPriceList);
  const minPrice = Math.min(...variancesPriceList);
  const isMinMaxPriceEqual = maxPrice === minPrice;

  return checkIsArrayEmpty(variances)
    ? '-'
    : isMinMaxPriceEqual
    ? constructDisplayPrice(maxPrice, currencyISO)
    : `${constructDisplayPrice(minPrice, currencyISO)} - ${constructDisplayPrice(maxPrice, currencyISO)}`;
};

const constructColumns = (t, currencyISO) => [
  {
    ...constructColumn(t('modalOverviewProductKeyword:table-header-label'), 'label', { hasAggregationSorter: true, width: '60%' }),
    ...constructColumnFilterSearch('label', t('modalOverviewProductKeyword:table-search-label'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('modalOverviewProductKeyword:table-header-qty-left'), 'inventory', { hasAggregationSorter: true, width: '20%' }),
    render: (inventory, record) => (
      <Row>
        <Col span={4}>{checkIsArrayEmpty(record.variances) ? '' : constructQtyLeftDisplay(record.keyword, record.variances)}</Col>
        <Col>{String(inventory)}</Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('modalOverviewProductKeyword:table-header-price'), 'price', { isPrice: true, width: '20%' }),
    render: (price, record) => (
      <Row>
        <Col>
          {checkIsArrayEmpty(record.variances)
            ? constructDisplayPrice(price, currencyISO)
            : constructPriceDisplayForProductWithVariances(record.variances, currencyISO)}
        </Col>
      </Row>
    )
  }
];

const constructDisplayPriceWithCurrency = (currencySymbol, currencyISO, productOrVariance) => {
  return `${currencySymbol}${constructDisplayPrice(productOrVariance.price, currencyISO)}`;
};

const constructCommentMessage = ({ selectedProducts, storeCurrency }) => {
  const { iso: currencyISO, symbol: currencySymbol } = storeCurrency;
  const hasSelectedVariance = selectedProducts.some(selectedProduct => selectedProduct.variances && selectedProduct.variances.length > 0);

  const formattedMessage = selectedProducts.reduce((commentMessage, selectedProduct) => {
    let message = '';
    const productLabel = selectedProduct.oriLabel;

    if (selectedProduct.variances && selectedProduct.variances.length > 0) {
      message = `${message}${productLabel}\n`;

      const activeProductVariances = selectedProduct.variances.filter(variance => !variance.disabled);
      if (selectedProduct.allBuyKeyword) {
        message = `${message}【${selectedProduct.allBuyKeyword}+?】${activeProductVariances.map(
          activeProductVariance => activeProductVariance.orderKeyword
        )} (ALL)\n`;
      }

      activeProductVariances.forEach(variance => {
        const displayPrice = constructDisplayPriceWithCurrency(currencySymbol, currencyISO, variance);
        message = `${message}【${variance.orderKeyword}+?】${variance.oriLabel} ${displayPrice}\n`;
      });
    } else {
      const productLabel = selectedProduct.oriLabel;
      const displayPrice = constructDisplayPriceWithCurrency(currencySymbol, currencyISO, selectedProduct);
      const baseProductMessage = `【${selectedProduct.orderKeyword}+?】${productLabel} ${displayPrice}`;

      if (hasSelectedVariance) {
        message = `${message}${productLabel}\n${baseProductMessage}\n`;
      } else {
        message = `${message}${baseProductMessage}`;
      }
    }

    if (commentMessage) {
      return `${commentMessage}\n${message}`;
    } else {
      return `${message}`;
    }
  }, '');

  return formattedMessage;
};

const ProductKeywordModal = ({ visible, onClose, onPost, isBtnLoading, storeCurrency }) => {
  const { t } = useTranslation(['common', 'modalOverviewProductKeyword']);
  const [form] = useForm();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productSuggestionsQuery, setProductSuggestionsQuery] = useState({ currentPage: 1, limit: 100 });
  const { isLoading: isProductSuggestionsLoading, paginatedData: productSuggestions, total: productSuggestionsTotal } = useGetProductSuggestions({
    sorter: { _id: -1 },
    ...productSuggestionsQuery,
    filter: { ...productSuggestionsQuery.filter, isRemoveDisabled: true }
  });

  useEffect(() => {
    // Effect for updating comment field when checked product change
    const newComment = constructCommentMessage({ selectedProducts, storeCurrency });
    form.setFieldsValue({ comment: newComment });
  }, [selectedProducts, form, t, storeCurrency]);

  const handleOnPostKeywords = () => {
    const comments = form.getFieldValue('comment');
    if (comments) {
      onPost(comments);
    }
    return;
  };

  const getFormattedProduct = product => {
    return {
      _id: product._id,
      orderKeyword: product.orderKeyword,
      oriLabel: product.oriLabel,
      variances: product.variances,
      price: product.price,
      allBuyKeyword: product.allBuyKeyword
    };
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      style={{ top: 40, minWidth: '70%' }}
      title={t('modalOverviewProductKeyword:modal-title', { count: productSuggestionsTotal || 0 })}
      footer={null}
    >
      <div>
        <Table
          size="small"
          rowKey={record => record._id}
          rowSelection={{
            selectedRowKeys: selectedProducts.map(selectedProduct => selectedProduct._id),
            onSelect: product => {
              const hasSelected = selectedProducts.find(selectedProduct => selectedProduct._id === product._id);
              setSelectedProducts(
                hasSelected
                  ? selectedProducts.filter(selectedProduct => selectedProduct._id !== product._id)
                  : [...selectedProducts, getFormattedProduct(product)]
              );
            },
            onSelectAll: isSelectAll => {
              setSelectedProducts(
                isSelectAll
                  ? [
                      ...new Map(
                        [...selectedProducts, ...productSuggestions.map(product => getFormattedProduct(product))].map(selectedProduct => [
                          selectedProduct._id,
                          selectedProduct
                        ])
                      ).values()
                    ]
                  : selectedProducts.filter(selectedProduct => !productSuggestions.find(product => product._id === selectedProduct._id))
              );
            }
          }}
          loading={isProductSuggestionsLoading}
          columns={constructColumns(t, storeCurrency.iso)}
          dataSource={productSuggestions}
          pagination={{ total: productSuggestionsTotal, defaultPageSize: 100 }}
          locale={{
            triggerDesc: t('common:table-header-sort-trigger-desc'),
            triggerAsc: t('common:table-header-sort-trigger-asc'),
            cancelSort: t('common:table-header-sort-cancel-sort')
          }}
          scroll={{ x: 'fit-content', y: '30vh' }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setProductSuggestionsQuery)}
        />
        <FormContentContainer>
          <Form form={form}>
            <FormInput
              name="comment"
              placeholder={t('modalOverviewProductKeyword:form-input-placeholder-live-comment')}
              type="textArea"
              extraProps={{
                maxLength: 2000,
                autoSize: {
                  minRows: 4
                },
                showCount: true
              }}
            />
          </Form>
        </FormContentContainer>
        <FormActionButtonsContainer>
          <Space wrap={true}>
            <Button type="secondary" onClick={() => onClose()} disabled={isBtnLoading}>
              {t('modalOverviewProductKeyword:modal-product-keyword-cancel-button-text')}
            </Button>
            <Button type="primary" icon={<MessageOutlined />} onClick={handleOnPostKeywords} loading={isBtnLoading}>
              {t('modalOverviewProductKeyword:modal-product-keyword-ok-button-text')}
            </Button>
          </Space>
        </FormActionButtonsContainer>
      </div>
    </Modal>
  );
};

export default withAppContext(ProductKeywordModal);

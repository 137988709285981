import styled from '@emotion/styled';
import { FontSizeOutlined, TagOutlined } from '@ant-design/icons';
import { Row, Button } from 'antd';

import ImagePreview from 'components/ImagePreview/ImagePreview';

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const StyledImagePreview = styled(ImagePreview)`
  height: 48px;
  width: 48px;
`;

export const TooltipQtyIcon = styled(TagOutlined)`
  cursor: pointer;
  color: ${props => (props.hasPreorderQty ? props.theme.color.red : props.theme.color.lightTertiary)};
`;

export const TooltipKeywordIcon = styled(FontSizeOutlined)`
  cursor: pointer;
  color: ${props => props.theme.color.lightTertiary};
`;

export const DownloadCSVButton = styled(Button)`
  background-color: ${props => props.theme.color.secondary};
  border-color: ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.white};

  &:hover {
    background-color: ${props => props.theme.color.secondary};
    border-color: ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.white};
  }
`;

export const ProductPendingOrderQuantity = styled.span`
  color: ${props => props.theme.color.grey};
  border-bottom: 1.8px dotted ${props => props.theme.color.grey};
  cursor: pointer;
`;

export const StyledCreatedText = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
  font-style: italic;
`;

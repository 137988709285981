import styled from '@emotion/styled';
import { Alert, Button, Card, Col, Row } from 'antd';

export const ContainerCard = styled(Card)`
  @media (min-width: 992px) {
    margin: 0vw 8vw;
  }

  @media (min-width: 1200px) {
    margin: 0vw 16vw;
  }
`;

export const RootContainer = styled(Row)`
  padding: 0;
`;

export const BankDetailLabel = styled(Row)`
  font-size: 16px;
  margin-bottom: 8px;
  color: ${props => props.theme.color.grey};
`;

export const TotalAmountText = styled.span`
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${props => props.theme.color.tertiary};
`;

export const PendingAmountText = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  color: ${props => props.theme.color.warn};
`;

export const BankDetailText = styled.span`
  margin-bottom: 8px;
  color: ${props => props.theme.color.grey};
  font-weight: 700;
`;

export const StoreName = styled.p`
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.tertiary};
  font-size: 24px;
  margin-bottom: 0px;
`;

export const StoreDescription = styled.p`
  color: ${props => props.theme.color.grey};
  font-size: 16px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;

export const CardTitle = styled.span`
  color: ${props => props.theme.color.tertiary};
`;

export const AlertContainer = styled.div`
  margin-bottom: 8px;
`;

export const CheckoutProductListCol = styled(Col)`
  padding-right: 0px;

  @media (min-width: 768px) {
    padding-right: 16px;
    height: ${props => (props.showBanner ? '68vh' : '72vh')};
    overflow-y: scroll;
  }

  @media (min-width: 992px) {
    height: ${props => (props.showBanner ? '60vh' : '65vh')};
  }
`;

export const CheckoutFormFieldsCol = styled(Col)`
  border-top: 1px solid #f0f0f0;
  padding-top: 24px;
  margin-top: 24px;

  @media (min-width: 768px) {
    border-top: 0px;
    padding-top: 0px;
    border-left: 1px solid #f0f0f0;
    padding-left: 8px;
    margin-top: 0px;

    height: ${props => (props.showBanner ? '68vh' : '72vh')};
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @media (min-width: 992px) {
    height: ${props => (props.showBanner ? '60vh' : '65vh')};
  }
`;

export const ShippingDetailsRow = styled(Row)`
  padding: 24px 0px 0px 0px;

  @media (min-width: 768px) {
    padding: 24px 24px 0 24px;
  }
`;

export const WNBAlert = styled(Alert)`
  margin-bottom: 24px;
  cursor: pointer;
  width: 100%;
`;

export const WNBMessageText = styled.p`
  margin-bottom: 0;
`;

export const WNBMessageTextHighlight = styled.span`
  border-bottom: 1px solid #000;
  padding-bottom: 2px;
`;

export const NoteBannerText = styled.p`
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;

export const LinkButton = styled(Button)`
  margin-left: 4px;
  padding: 0;
  height: 100%;
  line-height: 0;
`;

export const ResultTitleContainer = styled.div`
  @media (min-width: 768px) {
    line-height: 1.5em;
    margin-bottom: 16px;
  }
`;

export const FeaturedSectionTitle = styled.h2`
  color: ${props => props.theme.color.tertiary};
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 16px;
`;

export const FeatureSectionContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
  max-width: 768px;
  padding: 2px 12px;
  background-color: ${props => props.theme.color.lighterPrimary};
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 8px;
`;

export const FeatureProductsScrollableContainer = styled.div`
  width: 100%;
  padding: 8px 0;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FeaturedProductRow = styled(Row)`
  display: inline-flex;
  width: max-content;
`;

export const FeaturedProductCol = styled(Col)`
  width: 100%;
`;

export const PointsToEarnText = styled.p`
  font-size: 12px;
`;
